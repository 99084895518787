import qs from "qs"

const Cookie = process.client ? require('js-cookie') : undefined

export default function ({ $axios, store, redirect, app, error: nuxtError }) {

  $axios.defaults.paramsSerializer = function(params) {
    return qs.stringify(params)
  }
  
  $axios.onRequest(config => {
    config.headers.common['Authorization'] = config.url.split('/')[0] !== 'master' ? process.env.API_SECRET : process.env.API_SECRET_MASTER
    config.headers.common['token'] = `${store.state.member.auth.token}`
    config.headers.common['x-api-key'] = process.env.API_AUTH
  })

  $axios.onResponse(res => {
    const code = res.data.status

    // if (code === 400) {
    //   window.location = '/500'
    // }

    if (code === 401) {
      Cookie.remove('token')
      Cookie.remove('user')
      Cookie.remove('profile')
      Cookie.remove('fcm')
      Cookie.remove('notif')

      window.location = '/'
    }

    if (code === 500) {
      window.location = '/500'
    }
  })

  $axios.onError(error => {
    const code = parseInt(error.response && error.response.status)
    if (code === 400) {
      window.location = '/500'
    }
    
    if (code === 401) {
      Cookie.remove('token')
      Cookie.remove('user')
      Cookie.remove('profile')
      Cookie.remove('fcm')
      Cookie.remove('notif')

      window.location = '/'
    }

    if (code === 500) {
      window.location = '/500'
    }

    nuxtError({
      statusCode: error.response.status,
      message: error.message,
    })
    return Promise.resolve(false)
  })
}

