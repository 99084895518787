const state = () => ({
  popup: false
})

const mutations = {
  setPopup (state, popup) {
    state.popup = popup
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}